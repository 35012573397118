<section class="download-icons no-print row mb-4">
    <div class="col-md-11 text-md-right mt-4 mt-lg-0">
      <a
        href="javascript: void(0);"
        [routerLink]="['/', 'my-account/invoices']"
        class="c-link mr-3"
      >
        <fa-icon [icon]="faChevronLeft" class="icon"></fa-icon>Back to make a
        payment</a
      >
      <a (click)="excelDownload()" class="c-link mr-3"
        ><span>{{ 'makeAPayment.xls' | cxTranslate }}</span
        ><fa-icon [icon]="faFileExcel" class="icon"></fa-icon
      ></a>
      <a (click)="generatePDF()" class="c-link mr-3"
        ><span>{{ 'makeAPayment.pdf' | cxTranslate }}</span
        ><fa-icon [icon]="faFilePdf" class="icon"></fa-icon
      ></a>
      <a (click)="print()" class="c-link"
        ><span>{{ 'makeAPayment.print' | cxTranslate }}</span
        ><fa-icon [icon]="faPrint" class="icon"></fa-icon
      ></a>
    </div>
  </section>
  <section
    class="row makePaymentView u-border-left-grey pb-4"
    id="productList-area"
  >
    <section class="col-md-12 pay-section">
      <app-payment-header
        class="no-print"
        selectedHeader="confirmation"
        [tableCategory]="creditInvoice"
      >
      </app-payment-header>
      <div *ngIf="invoicePaymentList?.length > 0" class="row pl-md-3 mb-4">
        <div class="col-md-12 text-fixed-medium">
          <p *ngIf="!invoiceConfirmList?.authorization && authErrorMsg">
            {{ authErrorMsg }}
          </p>
          <p *ngIf="errorMsg">{{ errorMsg }}</p>
          <p *ngIf="errorMsg1">{{ errorMsg1 }}</p>
          <p *ngIf="personName">
            {{
              'makeAPayment.nameofPersonAuthorizingPayment' | cxTranslate
            }}&nbsp;{{ personName }}
          </p>
          <p>
            {{ 'makeAPayment.EmailforConfirmation' | cxTranslate }}&nbsp;<span
              *ngIf="!isAsmEnabled"
              >{{ userData?.email }}</span
            >
          </p>
          <p
            *ngIf="
              isAsmEnabled &&
              asmEmailDetails?.selectedEmail !== 'Add Other E-mail Address'
            "
          >
            {{ asmEmailDetails?.selectedEmail }}
          </p>
          <p
            *ngIf="
              isAsmEnabled &&
              asmEmailDetails?.selectedEmail === 'Add Other E-mail Address'
            "
          >
            {{ asmEmailDetails?.newEmail }}
          </p>
          <div
            class="col-xs-12 col-sm-12 col-md-12 p0 Fields-container register-zip reviewpayname"
          >
            <div class="pay-wrap">
              <!-- table starts -->
              <div class="col-md-12 tablemain-padding pad-lt">
                <div id="invoice-table">
                  <table
                    aria-describedby="confirmationTable"
                    id="applyCreditsTable"
                    class="payment-table display dataTable no-footer c-table c-table--branded d-none d-lg-block mt-2"
                  >
                    <thead>
                      <tr class="heading-table">
                        <th
                          id="table1col1"
                          class="sorting"
                          tabindex="0"
                          aria-controls="applyCreditsTable"
                          aria-label="Payment-option: activate to sort column ascending"
                        >
                          {{ 'makeAPayment.status' | cxTranslate }}&nbsp;
                        </th>
                        <th
                          id="table1col2"
                          class="sorting_desc"
                          tabindex="0"
                          aria-controls="applyCreditsTable"
                          aria-sort="descending"
                          aria-label="Invoice #: activate to sort column ascending"
                        >
                          {{ 'makeAPayment.invoice#' | cxTranslate }}
                        </th>
                        <th
                          id="table1col3"
                          class="sorting"
                          tabindex="0"
                          aria-controls="applyCreditsTable"
                          aria-label="Due date: activate to sort column ascending"
                        >
                          {{ 'makeAPayment.due_date' | cxTranslate }}
                        </th>
                        <th
                          id="table1col4"
                          class="pr8 sorting"
                          tabindex="0"
                          aria-controls="applyCreditsTable"
                          aria-label="Invoice balance: activate to sort column ascending"
                        >
                          {{ 'makeAPayment.netInvoiceBalance' | cxTranslate }}
                        </th>
                        <th
                          id="table1col5"
                          class="pr8 sorting_desc"
                          tabindex="0"
                          aria-controls="applyCreditsTable"
                          aria-label="Payment amount: activate to sort column ascending"
                        >
                          {{ 'makeAPayment.payment_amount' | cxTranslate }} &nbsp;
                        </th>
                        <th
                          id="table1col6"
                          class="sorting payment-method"
                          tabindex="0"
                          aria-controls="applyCreditsTable"
                          aria-label="Payment method: activate to sort column ascending"
                        >
                          {{ 'makeAPayment.payment_method' | cxTranslate }}
                        </th>
                        <th
                          id="table1col7"
                          class="sorting"
                          tabindex="0"
                          aria-controls="applyCreditsTable"
                          aria-label="Payment date: activate to sort column ascending"
                        >
                          {{ 'makeAPayment.payment_date' | cxTranslate }}
                        </th>
                      </tr>
                    </thead>
                    <tbody class="datatable-row popup-comp">
                      <tr
                        class="odd"
                        *ngFor="let data of invoiceConfirmList?.openinvoiceItems"
                      >
                        <td headers="table1col1">
                          {{ invoiceConfirmList?.message }}
                        </td>
                        <td headers="table1col2" class="darkblue sorting_1">
                          <a
                            *ngIf="
                              data?.docType === 'RV' ||
                              data?.docType === 'RD' ||
                              data?.docType === 'RF'
                            "
                            href="javascript: void(0);"
                            class="blue underline"
                            (click)="invoicePdf(data?.invoiceNumber)"
                          >
                            {{ data?.invoiceNumber }}
                          </a>
                          <a
                            *ngIf="
                              data?.docType === 'DZ' &&
                              data?.invoiceNumber?.startsWith('7')
                            "
                            href="javascript: void(0);"
                            class="blue underline"
                            (click)="invoicePdf(data?.invoiceNumber)"
                          >
                            {{ data?.invoiceNumber }}
                          </a>
                          <span
                            *ngIf="
                              data?.docType === 'DR' ||
                              data?.docType === 'DG' ||
                              data?.docType === 'YR' ||
                              data?.docType === 'DY'
                            "
                          >
                            {{ data?.invoiceNumber }}
                          </span>
                          <span
                            *ngIf="
                              data?.docType === 'DZ' &&
                              !data?.invoiceNumber?.startsWith('7')
                            "
                          >
                            {{ data?.invoiceNumber }}
                          </span>
                        </td>
                        <td headers="table1col3">{{ data?.dueDate }}</td>
                        <td headers="table1col4" class="">
                          ${{ data?.netBalance | number : '1.2-2' }}
                        </td>
                        <td headers="table1col5" class="">
                          ${{ data?.payAmount | number : '1.2-2' }}
                        </td>
                        <td headers="table1col6">
                          <img
                            src="../../../assets/icons/visa.png"
                            class="card-type"
                            *ngIf="data?.cardType === 'VISA'"
                            alt="VISA"
                          />
                          <img
                            src="../../../assets/icons/mastercard.png"
                            class="card-type"
                            alt="MASTER"
                            *ngIf="data?.cardType === 'MC'"
                          />
                          <span *ngIf="data?.cardType !== ''"
                            >#{{ dispCardNumber?.cardNumber }}</span
                          >
                        </td>
                        <td headers="table1col7">
                          <span *ngIf="data?.cardType !== ''">{{
                            currentDate | date : 'dd/MM/yyyy'
                          }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
  
                  <section class="confirmationTable-rwd d-block d-lg-none">
                    <div
                      class="row invoice-2col-row-rwd"
                      *ngFor="let data of invoiceConfirmList?.openinvoiceItems"
                    >
                      <div class="column invoice-header-rwd">
                        <section>
                          <p
                            id="table1col1"
                            class="sorting"
                            tabindex="0"
                            aria-controls="applyCreditsTable"
                            aria-label="Payment-option: activate to sort column ascending"
                          >
                            {{ 'makeAPayment.status' | cxTranslate }}
                          </p>
                          <p
                            id="table1col2"
                            class="sorting_desc"
                            tabindex="0"
                            aria-controls="applyCreditsTable"
                            aria-sort="descending"
                            aria-label="Invoice #: activate to sort column ascending"
                          >
                            {{ 'makeAPayment.invoice#' | cxTranslate }}
                          </p>
                          <p
                            id="table1col3"
                            class="sorting"
                            tabindex="0"
                            aria-controls="applyCreditsTable"
                            aria-label="Due date: activate to sort column ascending"
                          >
                            {{ 'makeAPayment.due_date' | cxTranslate }}
                          </p>
                          <p
                            id="table1col4"
                            class="pr8 sorting"
                            tabindex="0"
                            aria-controls="applyCreditsTable"
                            aria-label="Invoice balance: activate to sort column ascending"
                          >
                            {{ 'makeAPayment.netInvoiceBalance' | cxTranslate }}
                          </p>
                          <p
                            id="table1col5"
                            class="pr8 sorting_desc"
                            tabindex="0"
                            aria-controls="applyCreditsTable"
                            aria-label="Payment amount: activate to sort column ascending"
                          >
                            {{
                              'makeAPayment.payment_amount' | cxTranslate
                            }}&nbsp;
                          </p>
                          <p
                            id="table1col6"
                            class="sorting payment-method"
                            tabindex="0"
                            aria-controls="applyCreditsTable"
                            aria-label="Payment method: activate to sort column ascending"
                          >
                            {{ 'makeAPayment.payment_method' | cxTranslate }}
                          </p>
                          <p
                            id="table1col7"
                            class="sorting"
                            tabindex="0"
                            aria-controls="applyCreditsTable"
                            aria-label="Payment date: activate to sort column ascending"
                          >
                            {{ 'makeAPayment.payment_date' | cxTranslate }}
                          </p>
                        </section>
                      </div>
                      <div class="column invoice-desc-column">
                        <section>
                          <p headers="table1col1">
                            {{ invoiceConfirmList?.message }}
                          </p>
                          <p headers="table1col2" class="darkblue sorting_1">
                            <a
                              *ngIf="
                                data?.docType === 'RV' ||
                                data?.docType === 'RD' ||
                                data?.docType === 'RF'
                              "
                              href="javascript: void(0);"
                              class="blue underline"
                              (click)="invoicePdf(data?.invoiceNumber)"
                            >
                              {{ data?.invoiceNumber }}
                            </a>
                            <a
                              *ngIf="
                                data?.docType === 'DZ' &&
                                data?.invoiceNumber.startsWith('7')
                              "
                              href="javascript: void(0);"
                              class="blue underline"
                              (click)="invoicePdf(data?.invoiceNumber)"
                            >
                              {{ data.invoiceNumber }}
                            </a>
                            <span
                              *ngIf="
                                data?.docType === 'DR' ||
                                data?.docType === 'DG' ||
                                data?.docType === 'YR' ||
                                data?.docType === 'DY'
                              "
                            >
                              {{ data?.invoiceNumber }}
                            </span>
                            <span
                              *ngIf="
                                data?.docType === 'DZ' &&
                                !data?.invoiceNumber?.startsWith('7')
                              "
                            >
                              {{ data?.invoiceNumber }}
                            </span>
                          </p>
                          <p headers="table1col3">{{ data?.dueDate }}</p>
                          <p headers="table1col4 added-padding">
                            ${{ data?.netBalance | number : '1.2-2' }}
                          </p>
                          <p headers="table1col5">
                            ${{ data?.payAmount | number : '1.2-2' }}
                          </p>
                          <p headers="table1col6" class="mb15">
                            <img
                              src="../../../assets/icons/visa.png"
                              class="card-type"
                              *ngIf="data?.cardType === 'VISA'"
                              alt="VISA"
                            />
                            <img
                              src="../../../assets/icons/mastercard.png"
                              class="card-type"
                              alt="MASTER"
                              *ngIf="data?.cardType === 'MC'"
                            />
                            #{{ dispCardNumber?.cardNumber }}
                          </p>
                          <p headers="table1col7">
                            {{ currentDate | date : 'dd/MM/yyyy' }}
                          </p>
                        </section>
                      </div>
                    </div>
                  </section>
                </div>
                <hr />
                <div class="invoice-disc">
                  {{ 'makeAPayment.confirmationDiscription' | cxTranslate }}
                </div>
                <!--- table ends and Return to payment starts -->
                <div class="rtn_invoice no-print mt-4">
                  <div class="col-sm-12 col-md-12 col-lg-8 p0 ml10">
                    <a
                      href="javascript:void(0)"
                      role="presentation"
                      data-di-id="di-id-5e6145e6-291feed3"
                      ><button
                        class="c-button c-button--secondary"
                        (click)="return()"
                        tabindex="0"
                      >
                        {{ 'makeAPayment.return' | cxTranslate }}
                      </button></a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Credit Review Table -->
      <div *ngIf="creditPaymentList.length > 0" class="row pl-md-3 mb-4">
        <div class="col-md-12 text-fixed-medium">
          <div
            class="col-xs-12 col-sm-12 col-md-12 p0 Fields-container register-zip reviewpayname"
          >
            <p *ngIf="errorMsg">{{ errorMsg }}</p>
            <p *ngIf="errorMsg1">{{ errorMsg1 }}</p>
            <p *ngIf="personName">
              {{
                'makeAPayment.nameofPersonAuthorizingCredit' | cxTranslate
              }}:&nbsp;{{ personName }}
            </p>
            <p *ngIf="!isAsmEnabled && userData?.email">
              {{ 'makeAPayment.EmailforConfirmation' | cxTranslate }}&nbsp;{{
                userData?.email
              }}<span
                *ngIf="
                  isAsmEnabled &&
                  asmEmailDetails?.selectedEmail !== 'Add Other E-mail Address'
                "
                >{{ asmEmailDetails?.selectedEmail }}</span
              >
              <span
                *ngIf="
                  isAsmEnabled &&
                  asmEmailDetails?.selectedEmail === 'Add Other E-mail Address'
                "
                >{{ asmEmailDetails?.newEmail }}</span
              >
            </p>
            <p *ngIf="errorMsg1">{{ errorMsg1 }}</p>
            <div class="pay-wrap">
              <div class="col-md-12 tablemain-padding pad-lt">
                <div id="credits-table">
                  <table
                    aria-describedby="InvoiceDecriptionTable"
                    id="applyCreditsTable"
                    class="credit-table display dataTable no-footer c-table c-table--branded d-none d-lg-block mt-2"
                  >
                    <thead>
                      <tr class="heading-table">
                        <th
                          id="table1col1"
                          class="sorting"
                          tabindex="0"
                          aria-controls="applyCreditsTable"
                          aria-label="Status: activate to sort column ascending"
                        >
                          {{ 'makeAPayment.status' | cxTranslate }}&nbsp;
                        </th>
                        <th
                          id="table1col2"
                          class="sorting_desc"
                          tabindex="0"
                          aria-controls="applyCreditsTable"
                          aria-sort="descending"
                          aria-label="Credit #: activate to sort column ascending"
                        >
                          {{ 'makeAPayment.credit#' | cxTranslate }} &nbsp;
                        </th>
                        <th
                          id="table1col3"
                          class="pr8 sorting"
                          tabindex="0"
                          aria-controls="applyCreditsTable"
                          aria-label="Apply to invoice#: activate to sort column ascending"
                        >
                          {{ 'makeAPayment.apply_to_invoice' | cxTranslate }}
                        </th>
                        <th
                          id="table1col4"
                          class="pr8 sorting"
                          tabindex="0"
                          aria-controls="applyCreditsTable"
                          aria-label="Credit applied: activate to sort column ascending"
                        >
                          {{ 'makeAPayment.credit_applied' | cxTranslate }}
                        </th>
                        <th
                          id="table1col5"
                          class="pr8 sorting"
                          tabindex="0"
                          aria-controls="applyCreditsTable"
                          aria-label="New Credit balance: activate to sort column ascending"
                        >
                          {{
                            'makeAPayment.newCreditBalance' | cxTranslate
                          }}&nbsp;
                        </th>
                        <th
                          id="table1col6"
                          class="sorting"
                          tabindex="0"
                          aria-controls="applyCreditsTable"
                          aria-label="New invoice balance: activate to sort column ascending"
                        >
                          {{ 'makeAPayment.newInvoiceBalance' | cxTranslate }}
                        </th>
                        <th
                          id="table1col7"
                          class="sorting"
                          tabindex="0"
                          aria-controls="applyCreditsTable"
                          aria-label="Credit applied date: activate to sort column ascending"
                        >
                          {{ 'makeAPayment.creditAppliedDate' | cxTranslate }}
                        </th>
                      </tr>
                    </thead>
  
                    <tbody class="datatable-row popup-comp">
                      <tr
                        class="odd"
                        *ngFor="
                          let data of creditConfirmationList?.invoiceCreditPayResponseList
                        "
                      >
                        <td headers="table1col1">{{ data?.msg }}</td>
                        <td headers="table1col2">
                          <a
                            *ngIf="
                              data?.credDocType === 'RV' ||
                              data?.credDocType === 'RD' ||
                              data?.credDocType === 'RF'
                            "
                            href="javascript: void(0);"
                            class="blue underline"
                            (click)="creditPdf(data?.credRefDocnum)"
                          >
                            {{ data?.credRefDocnum }}
                          </a>
                          <a
                            *ngIf="
                              data?.credDocType === 'DZ' &&
                              data?.credAlloc_nmbr?.startsWith('7')
                            "
                            href="javascript: void(0);"
                            class="blue underline"
                            (click)="creditPdf(data?.credAlloc_nmbr)"
                          >
                            {{ data?.credAlloc_nmbr }}
                          </a>
                          <span
                            *ngIf="
                              data?.credDocType === 'DR' ||
                              data?.credDocType === 'DG' ||
                              data?.credDocType === 'DY'
                            "
                          >
                            {{ data?.credRefDocnum }}
                          </span>
                          <span *ngIf="data?.credDocType === 'YR'">
                            {{ data?.creditRefhdr }}
                          </span>
                          <span
                            *ngIf="
                              data?.credDocType === 'DZ' &&
                              !data?.credAlloc_nmbr?.startsWith('7')
                            "
                          >
                            {{ data?.credAlloc_nmbr }}
                          </span>
                        </td>
                        <td headers="table1col3">
                          <span
                            *ngIf="
                              data?.invDocType !== 'DZ' &&
                              data?.invDocType !== 'YR'
                            "
                          >
                            {{ data?.invRefDocnum }}
                          </span>
                          <span *ngIf="data?.invDocType === 'YR'">
                            {{ data?.invRefhdr }}
                          </span>
                          <span *ngIf="data?.invDocType === 'DZ'">
                            {{ data?.invAlloc_nmbr }}
                          </span>
                        </td>
                        <td headers="table1col4">
                          <span>{{ data?.invAmt }}</span>
                        </td>
                        <td headers="table1col5">{{ data?.credAmt }}</td>
                        <td headers="table1col6">{{ data?.invBal }}</td>
                        <td headers="table1col7">
                          <span> {{ currentDate | date : 'dd/MM/yyyy' }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
  
                  <section class="invoicepayment-table-2column d-block d-lg-none">
                    <div
                      class="row invoice-2col-row-rwd"
                      *ngFor="
                        let data of creditConfirmationList?.invoiceCreditPayResponseList
                      "
                    >
                      <div class="column invoice-header-rwd">
                        <section>
                          <p
                            id="table1col1"
                            class="sorting"
                            tabindex="0"
                            aria-controls="applyCreditsTable"
                            aria-label="Status: activate to sort column ascending"
                          >
                            {{ 'makeAPayment.status' | cxTranslate }}
                          </p>
                          <p
                            id="table1col2"
                            class="sorting_desc"
                            tabindex="0"
                            aria-controls="applyCreditsTable"
                            aria-sort="descending"
                            aria-label="Credit #: activate to sort column ascending"
                          >
                            {{ 'makeAPayment.credit#' | cxTranslate }} &nbsp;
                          </p>
                          <p
                            id="table1col3"
                            class="pr8 sorting"
                            tabindex="0"
                            aria-controls="applyCreditsTable"
                            aria-label="Apply to invoice#: activate to sort column ascending"
                          >
                            {{ 'makeAPayment.apply_to_invoice' | cxTranslate }}
                          </p>
                          <p
                            id="table1col4"
                            class="pr8 sorting"
                            tabindex="0"
                            aria-controls="applyCreditsTable"
                            aria-label="Credit applied: activate to sort column ascending"
                          >
                            {{ 'makeAPayment.credit_applied' | cxTranslate }}
                          </p>
                          <p
                            id="table1col5"
                            class="pr8 sorting"
                            tabindex="0"
                            aria-controls="applyCreditsTable"
                            aria-label="New Credit balance: activate to sort column ascending"
                          >
                            {{
                              'makeAPayment.newCreditBalance' | cxTranslate
                            }}&nbsp;
                          </p>
                          <p
                            id="table1col6"
                            class="pr8 sorting"
                            tabindex="0"
                            aria-controls="applyCreditsTable"
                            aria-label="New invoice balance: activate to sort column ascending"
                          >
                            {{
                              'makeAPayment.newInvoiceBalance' | cxTranslate
                            }}&nbsp;
                          </p>
                          <p
                            id="table1col7"
                            class="sorting"
                            tabindex="0"
                            aria-controls="applyCreditsTable"
                            aria-label="Credit applied date: activate to sort column ascending"
                          >
                            {{ 'makeAPayment.creditAppliedDate' | cxTranslate }}
                          </p>
                        </section>
                      </div>
                      <div class="column invoice-desc-column">
                        <section>
                          <p headers="table1col1">{{ data?.msg }}</p>
                          <p headers="table1col2">
                            <a
                              *ngIf="
                                data?.credDocType === 'RV' ||
                                data?.credDocType === 'RD' ||
                                data?.credDocType === 'RF'
                              "
                              href="javascript: void(0);"
                              class="blue underline"
                              (click)="creditPdf(data?.credRefDocnum)"
                            >
                              {{ data?.credRefDocnum }}
                            </a>
                            <a
                              *ngIf="
                                data?.credDocType === 'DZ' &&
                                data?.credAlloc_nmbr?.startsWith('7')
                              "
                              href="javascript: void(0);"
                              class="blue underline"
                              (click)="creditPdf(data?.credAlloc_nmbr)"
                            >
                              {{ data?.credAlloc_nmbr }}
                            </a>
                            <span
                              *ngIf="
                                data?.credDocType === 'DR' ||
                                data?.credDocType === 'DG' ||
                                data?.credDocType === 'DY'
                              "
                            >
                              {{ data?.credRefDocnum }}
                            </span>
                            <span *ngIf="data?.credDocType === 'YR'">
                              {{ data?.creditRefhdr }}
                            </span>
                            <span
                              *ngIf="
                                data?.credDocType === 'DZ' &&
                                !data?.credAlloc_nmbr?.startsWith('7')
                              "
                            >
                              {{ data?.credAlloc_nmbr }}
                            </span>
                          </p>
                          <p headers="table1col3">
                            <span
                              *ngIf="
                                data?.invDocType !== 'DZ' ||
                                data?.invDocType !== 'YR'
                              "
                            >
                              {{ data?.invRefDocnum }}
                            </span>
                            <span *ngIf="data?.invDocType === 'YR'">
                              {{ data?.invRefhdr }}
                            </span>
                            <span *ngIf="data?.invDocType === 'DZ'">
                              {{ data?.invAlloc_nmbr }}
                            </span>
                          </p>
                          <p headers="table1col4">{{ data?.invAmt }}</p>
                          <p headers="table1col5">{{ data?.credAmt }}</p>
                          <p headers="table1col6">{{ data?.invBal }}</p>
                          <p headers="table1col7">
                            {{ currentDate | date : 'dd/MM/yyyy' }}
                          </p>
                        </section>
                      </div>
                    </div>
                  </section>
                </div>
                <!--- table ends and Return to payment starts -->
                <div class="rtn_invoice credit-confirm no-print">
                  <div class="col-sm-12 col-md-12 col-lg-8 p0">
                    <a
                      href="javascript:void(0)"
                      role="presentation"
                      data-di-id="di-id-5e6145e6-291feed3"
                      ><button
                        class="c-button c-button--secondary"
                        (click)="return()"
                        tabindex="0"
                      >
                        {{ 'makeAPayment.return' | cxTranslate }}
                      </button></a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <!--- table ends and Return to payment starts -->
      </div>
    </section>
  </section>
  
  <div class="hide-section" *ngIf="isPDF">
    <div id="line-sanofi">
      <div class="col-md-12 line-bottom-sanofi-pdf">
        <div class="col-md-12 pdf-img">
          <img
            src="../../assets/img/vs-canada-logo-header.png"
            alt="PDF"
            class="ad-pdf"
          />
        </div>
        <div class="col-md-12">
          <h3 class="sub-label-pdf" *ngIf="creditInvoice === 'invoiceTable'">
            {{ 'makeAPayment.payment_title' | cxTranslate }}
          </h3>
          <h3 class="sub-label-pdf" *ngIf="creditInvoice === ''">Pay credits</h3>
        </div>
      </div>
      <div class="pdf-name">
        <label class="Fields-label step2-name">
          <span>
            <span *ngIf="creditInvoice === 'invoiceTable'">{{
              'makeAPayment.nameofPersonAuthorizingPayment' | cxTranslate
            }}</span>
            <span *ngIf="creditInvoice !== 'invoiceTable'">{{
              'makeAPayment.nameofPersonAuthorizingCredit' | cxTranslate
            }}</span>
          </span>
          <span>{{ personName }}</span>
        </label>
      </div>
    </div>
    <section id="pdf-dummy"></section>
  </div>
  