<div class="modal-header">
    <button
      type="button"
      class="close-banner"
      aria-label="Close"
      (click)="activeModal.dismiss('Cross click')"
    >
      <!-- <img alt="" src="../../assets/icons/close.png"> -->
  
      <i
        class="fa-solid fa-x c-icon-primary c-icon-primary-sm c-icon-round-hover mr-3 mt-4"
        (click)="activeModal.dismiss('Cross click')"
      ></i>
    </button>
    <h2
      class="c-heading-36 font-bold u-border-bottom-grey pb-4 w-100"
      id="modal-basic-title"
    >
      Add new card details
    </h2>
  </div>
  <div class="modal-body">
    <iframe
      title=""
      #iframe
      id="valid-for-data"
      [height]="550"
      [width]="400"
      [src]="urlSafe"
      name="valid-for-data"
      (load)="onload()"
      *ngIf="iframeLoad"
    ></iframe>
  </div>
  <div class="modal-footer u-border-top-grey">
    <div class="panel pay-invioce-panel" *ngIf="isCheckbox">
      <label class="adding-card-checkbox">
        <input
          type="checkbox"
          class="save-check"
          [checked]="defaultCard"
          [attr.disabled]="defaultCard ? true : null"
          (change)="saveCard($event)"
        />
        &nbsp;&nbsp;&nbsp; Add this card to the account on file
      </label>
    </div>
    <div class="panel pay-invioce-panel">
      <label class="adding-card-checkbox">
        <input type="checkbox" class="input-check" (change)="GetStats($event)" />
        &nbsp;&nbsp; Make this my default card
      </label>
    </div>
    <div class="d-flex mt-2">
      <button
        class="c-button c-button--secondary col-md-6 pr-2"
        (click)="activeModal.close('Close click')"
      >
        Cancel
      </button>
      &nbsp; &nbsp;
      <button
        class="c-button c-button--primary col-md-6 pl-2"
        (click)="onSubmitIFrame()"
      >
        Add card
      </button>
    </div>
  </div>
  