import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseSiteService } from '@spartacus/core';
import { UserAccountFacade } from '@spartacus/user/account/root';
import { Subject, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { CustomAddToCartService } from '../../custom-add-to-cart/custom-add-to-cart.service';
import { LoactionStripService } from '../../shared/modules/location-strip/loaction-strip.service';
import { SharedService } from '../../shared/services/shared.service';

@Component({
  selector: 'app-custom-header-change-account',
  templateUrl: './custom-header-change-account.component.html',
  styles: [
    '.header-modal .modal-dialog { overflow: hidden; min-height: 655px; }',
    '.header-modal .modal-dialog .modal-content { flex-shrink: 0; }',
    '.header-modal .modal-dialog .modal-content.step-1.move-left{ display: none }',
  ]
})
export class CustomHeaderChangeAccountComponent implements OnInit, OnDestroy {
  usergetDataObj: any;
  userAccountId: string;
  userAccountgetData: any;
  showLocStrip = false;
  showChangeOption: boolean;
  baseSiteId: string;
  selectedSoldToData: any;
  defaultLocationTitle: string;
  locationTitle: string;
  soldToLocations: Array<any>;
  soldToSearchedLocations: Array<any>;
  userAccountSubscriber: Subscription;
  userAccountDataSubscriber: Subscription;
  soldToAddressesSubscriber: Subscription;
  shipToMultipleSubscriber: Subscription;
  selectedAccountSub: any;
  modalStep1Move: boolean = false;
  private destroySubject = new Subject<void>();
  shipToMultiple : boolean = false;
  shipToLocations: Array<any>;
  shipToSearchedLocations: Array<any>;
  selectedShipToData: any;
  cartItems: any;
  cartId: any;
  noShipLoc: boolean = false;
  @ViewChild('content') modalContent;
  showChangeShip: boolean = false;
  shipToAddressSubscriber: Subscription;
  userSubscriber: Subscription;
  constructor(
    private readonly locService: LoactionStripService,
    private readonly user: UserAccountFacade,
    private readonly ref: ChangeDetectorRef,
    private readonly baseSiteService: BaseSiteService,
    protected readonly modal: NgbModal,
    private readonly sharedService: SharedService,
    private readonly addToCart: CustomAddToCartService,
  ) {}

  ngOnInit(): void {
    // Get Current B2BUnit
    this.userSubscriber = this.user.get().subscribe((res) => {
      // If there are results
      if (res) {
        this.userAccountgetData = res;
        this.showLocStrip = true;
        this.sharedService.setSessionStorage(
          'userAccountId',
          this.userAccountgetData.orgUnit.uid
        );
        this.userAccountId = this.userAccountgetData.orgUnit.uid
        this.ref.detectChanges();
      } else {
        this.showLocStrip = false;
      }
      this.ref.detectChanges();
    });



    // Get a list of all the Sold To addresses
    this.userAccountSubscriber = this.locService.userAccountAddressDetails.pipe(take(2)).subscribe((response) => {
      if (response?.res) {
        // Array with list of sold to locations
        this.soldToLocations = response.res?.addresses as Array<{}>;
        // Variable of sold to locations, used for searching
        this.soldToSearchedLocations = this.soldToLocations;
        this.getShipToAddress(response?.res,'first-load');
        if ((!!this.soldToLocations?.length && this.soldToLocations?.length > 1)) {
          this.showChangeOption = true;
          // If the popup was not shown open the modal
          if (!this.sharedService.getSessionStorage('isShowFlag')) {
            this.openModal(this.modalContent);
          }
          this.showChangeShip = true
        } else {
          this.showChangeOption = false;
          this.showChangeShip = false;
        };
        this.ref.detectChanges();
      }
      this.ref.detectChanges();
    });
    this.baseSiteService
      .getActive()
      .pipe(take(1))
      .subscribe((res) => (this.baseSiteId = res));
      this.ref.detectChanges();
  }

 
  modalStepTwo() : void {
    this.modalStep1Move = true;
  }
  openModal(content):void {
    this.modal.open(content, {
      centered: true,
      backdrop: 'static',
      windowClass: `locStripModal ${this.baseSiteId}`,
    });
    this.sharedService.setSessionStorage('isShowFlag', true);
  }

  SearchClientName(data: any) {
    if (data.length > 0) {
      this.soldToLocations = this.soldToSearchedLocations.filter(
        (x) =>
          (x.companyName != undefined &&
            x.companyName.toLowerCase().indexOf(data.toLowerCase()) >= 0) ||
          (x.unitID != undefined &&
            x.unitID.toLowerCase().indexOf(data.toLowerCase()) >= 0)
      );
    } else {
      this.soldToLocations = this.soldToSearchedLocations;
    }
  }

  SearchShipTo(data: any) {
    if (data.length > 0) {
      this.shipToLocations = this.shipToSearchedLocations.filter(
        (x) =>
          (x.companyName != undefined &&
            x.companyName.toLowerCase().indexOf(data.toLowerCase()) >= 0) ||
          (x.unitID != undefined &&
            x.unitID.toLowerCase().indexOf(data.toLowerCase()) >= 0)
      );
    } else {
      this.shipToLocations = this.shipToSearchedLocations;
    }
  }
  
  onOptionsSelected(data) {
    this.selectedSoldToData = data;
    this.getShipToAddress(data?.sapCustomerID,'option-selected');
    this.ref.detectChanges();
  }
  onShipToSelected(data) {
    this.selectedShipToData = data;
  }

  getShipToAddress(sapCustomerId,loc) : void {
    let currentSapCustomerId;
    if (loc == 'first-load') {
      currentSapCustomerId = this.userAccountId;
    } else {
      currentSapCustomerId = sapCustomerId;     
    }
    this.locService.getShipToToAddress(this.userAccountgetData?.userName, currentSapCustomerId);
    this.shipToAddressSubscriber = this.locService.$shipToAddress.pipe(
      take(1)
    ).subscribe((res) => {
      this.shipToLocations = res?.res?.addresses;
      this.shipToSearchedLocations = this.shipToLocations;
      if (res && res?.res?.addresses.length > 1) {
        this.shipToMultiple = true
        this.noShipLoc = false;
        if (loc == 'first-load' && !this.sharedService.getSessionStorage('isShowFlag')) {
          this.openModal(this.modalContent);
          if ( this.soldToLocations?.length == 1) {
            this.modalStep1Move = true;
          }
        }
      } 
      if (!this.noShipLoc && res && res?.res?.addresses.length ==1) {
        this.shipToMultiple = false;
      }
      if(this.shipToLocations?.length == 0) {
        this.noShipLoc = true;
      }
    });
    this.ref.detectChanges();
  }

  changeAccount() {  
    let asmUserId = localStorage.getItem('asmUserId');
    let shipTo;
    if ( !this.sharedService.getLocalStorage('asmEnabled') && !asmUserId) {
      asmUserId = 'current'
    } 
    let unitId;
    if ( this.selectedSoldToData?.unitID == undefined) {
      unitId = this.userAccountgetData.orgUnit.uid;
    } else {
      unitId = this.selectedSoldToData?.unitID;
    }
    this.locService.saveSelectedAccount(asmUserId,unitId);

    
    if (this.selectedShipToData?.unitID == undefined ) {
      shipTo = this.shipToLocations[0]?.sapCustomerID;
    } else {
      shipTo = this.selectedShipToData?.unitID;
    }
    this.locService.saveSelectedShipToAddress(asmUserId,shipTo);
    this.updateShipToAddressForCart(shipTo);
    this.getShipToAddress(shipTo,'chang-account');
    this.ref.detectChanges();
    this.modal.dismissAll();
    this.modalStep1Move = false;
  }

  updateShipToAddressForCart(unitID: any) {
    let unit;
    if (unitID == undefined) {
      unit = this.selectedSoldToData;
    }
    this.addToCart.allCartItems$.subscribe(
      (res1) => {
        if (res1) {
          this.cartItems = res1?.carts;
          this.cartItems = this.cartItems.filter(
            (item) => item?.saveTime === undefined,
          );
          this.cartItems = this.cartItems[this.cartItems?.length - 1];
          this.cartId = this.cartItems?.code;
          if (this.cartId !== undefined && this.cartId.length > 0) {
            this.addToCart.saveLocation(
              unitID,
              this.cartId,
              this.userAccountgetData?.userName,
            );
          }
        }
      }
    ).unsubscribe();
  }

  ngOnDestroy() {
    this.userSubscriber?.unsubscribe();
    this.userAccountSubscriber?.unsubscribe();
    this.userAccountDataSubscriber?.unsubscribe();
    this.shipToMultipleSubscriber?.unsubscribe();
    this.shipToAddressSubscriber?.unsubscribe();
    this.destroySubject?.next();
    this.destroySubject?.unsubscribe()
    sessionStorage.removeItem('isShowFlag');
    sessionStorage.removeItem('userAccountId');
  }
}
