<div id="location-select" [ngClass]="{ 'd-none': !showChangeOption }">
  <div class="locationDetails" *ngIf="showChangeShip">
    <span (click)="openModal(content)">{{
      'header.changeAccount' | cxTranslate
    }}</span>
  </div>

  <!-- modal content-->
  <section class="checkout-modal">
    <section class="modal-dialog">
      <section class="modal-content">
        <ng-template #content let-modal>
          <section class="header-modal checkout-modal modal-selector modal-with-select">
            <section class="modal-dialog d-flex">
              <!-- Step 1 -->
              <section class="modal-content step-1" [ngClass]="{'move-left': modalStep1Move }">
                <div class="close">
                  <i
                    class="fa-solid fa-x c-icon-primary c-icon-primary-sm c-icon-round-hover mr-3 mt-4"
                    (click)="modal.dismiss('Cross click')"
                  ></i>
                </div>
                <section class="modal-header cart-Modal-Header">
                  <h2
                    class="c-heading-36 font-bold u-border-bottom-grey pb-4 w-100"
                    id="modal-basic-title"
                  >
                    {{ 'header.selectAccount' | cxTranslate }}
                  </h2>
                </section>
                <section class="modal-body">
                  <section class="input-group loc-SearchBar">
                    <div class="c-text-input">
                      <cx-icon class="cx-icon fas fa-search"></cx-icon>
                      <input
                        type="text"
                        #clientName
                        (keyup)="SearchClientName(clientName.value)"
                        class="form-control searchboxnew"
                        placeholder="{{
                          'header.filterBySoldoToAccount' | cxTranslate
                        }}"
                      />
                    </div>
                  </section>
                  <section>
                    <section
                      [ngClass]="{ displayNone: soldToLocations?.length === 0 }"
                      class="custom-radio radio_grp label-location w-100 mt-4"
                    >
                      <table
                        aria-describedby="shiptolocation"
                        *ngFor="let data of soldToLocations; let i = index"
                        class="w-100 mt-2"
                      >
                        <tr
                          class="line-location"
                          [ngClass]="{
                            'label-location': i !== soldToLocations.length - 1
                          }"
                        >
                          <td class="u-border-top-grey pt-1">
                            <input
                              type="radio"
                              (change)="onOptionsSelected(data)"
                              [id]="data?.unitID"
                              [checked]="userAccountId == data?.unitID"
                              name="customRadio"
                              class="custom-control-input"
                            /> 
                            <label
                              class="custom-control-label secloct w-100 text-fixed-medium font-bold"
                              [for]="data?.unitID"
                            >
                              {{ data?.companyName }}
                              {{ '(' + data?.unitID + ')' }}
                            </label>
                            <p class="labelpp mb-2 text-fixed-medium">
                              {{ data?.line1 ? data?.line1 + ', ' : '' }}
                              {{ data?.line2 ? data?.line2 + ', ' : '' }}
                              {{ data?.town ? data?.town + ', ' : '' }}
                              {{
                                data?.region?.isocodeShort
                                  ? data?.region?.isocodeShort + ' '
                                  : ''
                              }}
                              {{ data?.postalCode }}
                            </p>
                          </td>
                        </tr>
                      </table>
                    </section>
                    <section
                      class="custom-radio radio_grp label-location w-100 mt-4"
                      *ngIf="soldToLocations?.length === 0"
                    >
                      <p
                        class="u-border-top-grey pt-4 text-fixed-medium font-bold"
                      >
                        {{ 'account.noAccountResults' | cxTranslate }}
                      </p>
                    </section>
                  </section>
                </section>
                <section class="modal-footer justify-content-start">
                  <button
                    type="button"
                    class="c-button c-button--primary ml-3"
                    (click)="modalStep1Move = true"
                    *ngIf="shipToMultiple && !noShipLoc"
                    [disabled]="soldToLocations.length == 0"
                  >
                    {{ 'account.next' | cxTranslate }}
                  </button>
                  <button
                    type="button"
                    class="c-button c-button--primary ml-3"
                    (click)="changeAccount()"
                    *ngIf="!shipToMultiple && !noShipLoc"
                    [disabled]="soldToLocations.length == 0"
                  >
                    {{ 'account.done' | cxTranslate }}
                  </button>
                  <p *ngIf="noShipLoc" class="mx-4 c-text color-error">
                    We couldn't find a shipping address for this account. Please try a different account or contact customer service.
                  </p>
                </section>
              </section>
              <!-- Step 2 -->
              <section class="modal-content step-2">
                <div class="close">
                  <i
                    class="fa-solid fa-x c-icon-primary c-icon-primary-sm c-icon-round-hover mr-3 mt-4"
                    (click)="modal.dismiss('Cross click')"
                  ></i>
                </div>
                <section class="modal-header cart-Modal-Header">
                  <h2
                    class="c-heading-36 font-bold u-border-bottom-grey pb-4 w-100"
                    id="modal-basic-title"
                  >
                    {{ 'header.selectShipToAccount' | cxTranslate }}
                  </h2>
                </section>
                <section class="modal-body">
                  <section class="input-group loc-SearchBar">
                    <div class="c-text-input">
                      <cx-icon class="cx-icon fas fa-search"></cx-icon>
                      <input
                        type="text"
                        #ship
                        (keyup)="SearchShipTo(ship.value)"
                        class="form-control searchboxnew"
                        placeholder="{{
                          'header.filterBySoldoToAccount' | cxTranslate
                        }}"
                      />
                    </div>
                  </section>
                  <section>
                    <section
                      [ngClass]="{ displayNone: shipToLocations?.length === 0 }"
                      class="custom-radio radio_grp label-location w-100 mt-4"
                    >
                      <table
                        aria-describedby="shiptolocation"
                        *ngFor="let data of shipToLocations; let i = index"
                        class="w-100 mt-2"
                      >
                        <tr
                          class="line-location"
                          [ngClass]="{
                            'label-location': i !== shipToLocations.length - 1
                          }"
                        >
                          <td class="u-border-top-grey pt-1">
                            <input
                              type="radio"
                              (change)="onShipToSelected(data)"
                              [id]="'ship-'+data?.unitID+i"
                            
                              name="customRadio"
                              class="custom-control-input"
                            />
                            <label
                              class="custom-control-label secloct w-100 text-fixed-medium font-bold"
                              [for]="'ship-'+data?.unitID+i"
                            >
                              {{ data?.companyName }}
                              {{ '(' + data?.unitID + ')' }}
                            </label>
                            <p class="labelpp mb-2 text-fixed-medium">
                              {{ data?.line1 ? data?.line1 + ', ' : '' }}
                              {{ data?.line2 ? data?.line2 + ', ' : '' }}
                              {{ data?.town ? data?.town + ', ' : '' }}
                              {{
                                data?.region?.isocodeShort
                                  ? data?.region?.isocodeShort + ' '
                                  : ''
                              }}
                              {{ data?.postalCode }}
                            </p>
                          </td>
                        </tr>
                      </table>
                    </section>
                    <section
                      class="custom-radio radio_grp label-location w-100 mt-4"
                      *ngIf="shipToLocations?.length === 0"
                    >
                      <p
                        class="u-border-top-grey pt-4 text-fixed-medium font-bold"
                      >
                        {{ 'account.noAccountResults' | cxTranslate }}
                      </p>
                    </section>
                  </section>
                </section>
                <section class="modal-footer justify-content-start">
                  <button
                  *ngIf="soldToLocations?.length > 1 "
                  type="button"
                    class="c-button c-button--secondary ml-3"
                    (click)="modalStep1Move = false"
                   >
                   {{ 'accountRegistration.form.back_button' | cxTranslate }}
                  </button>
                  <button
                    type="button"
                    class="c-button c-button--primary ml-3"
                    (click)="changeAccount()"
                    [disabled]="shipToLocations && shipToLocations.length == 0"
                  >
                    {{ 'account.done' | cxTranslate }}
                  </button>
                </section>
              </section>
            </section>
          </section>
        </ng-template>
      </section>
    </section>
  </section>
</div>
